<template>
  <div id="map" style="width: 100%; height: calc(100% - 50px)"></div>
</template>
<script>
import * as echarts from "echarts";
import { getLocationInfo, getMapDistributionData } from "@/api/dataAnalysis/largeScreen";
import axios from "axios";
export default {
  name: "cityMap",
  data() {
    return {
      mapObjChart: null,
      allRegion: [],
      locationCenterInfo: [],
      level: 0,
    };
  },
  methods: {
    getOption(res, { zoom = 1.6 }, map, center) {
      return {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        geo: {
          show: true,
          center: center || [104.114129, 35.550339],
          map,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: true,
              color: "#fff",
              fontSize: 14,
            },
          },
          zLevel: 110,
          zoom,
          roam: true,
          itemStyle: {
            areaColor: {
              type: "radial",
              x: 0.5,
              y: 0.5,
              r: 3,
              colorStops: [
                {
                  offset: 0,
                  color: "#415874",
                  // color: "rgba(223, 231, 242, 1)", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#415874",
                  // color: "rgba(2, 99, 206, 1)", // 100% 处的颜色
                },
              ],
            },
            emphasis: {
              areaColor: {
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(223, 231, 242, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(2, 99, 206, 1)", // 100% 处的颜色
                  },
                ],
              },
              borderColor: "rgba(104, 152, 190, 1)", // 边框样式
              shadowColor: "rgba(128, 217, 248, 1)", // 阴影颜色
            },
          },
        },
        series: [
          {
            name: "车辆数量",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 111, // 增加zleve属性可降低动画导致的CPU占用率过高问题
            data: res ? res : [],
            showEffectOn: "render",
            rippleEffect: {
              //  涟漪特效定制
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 2, //波纹圆环最大限制，值越大波纹越大 4
            },
            encode: {
              value: 2,
            },
            symbolSize: function (val) {
              return 12;
            },
            label: {
              formatter: "{b}",
              position: "right",
            },
            itemStyle: {
              color: "#ddb926",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        ],
      };
    },
    async initChart(res, { zoom = 1.6 }, map, center) {
      const el = document.getElementById("map");
      if (!echarts.getInstanceByDom(el)) {
        this.mapObjChart = echarts.init(el);
      }
      this.mapObjChart.clear();
      this.mapObjChart.setOption(this.getOption(res, zoom, map, center));
      // this.mapObjChart.off("click");
      this.mapObjChart.getZr().on("click", async (obj) => {
        // console.log(obj);

        const name = obj.name;
        if (this.level < 2) {
          this.level++;
        } else {
          return;
        }
        const arr = [];
        const res = await getMapDistributionData({ level: this.level, name });
        res.data.map((item) => {
          const { name, longitude, latitude, vehicleNumbers } = item;
          arr.push({
            name: name,
            value: [Number(longitude), Number(latitude), vehicleNumbers],
          });
        });
        const { data } = await getLocationInfo({ locationName: name });
        const temp = this.locationCenterInfo.filter((item) => item.locationName == name);
        echarts.registerMap(name, JSON.parse(data.locationMapInfo));
        if (temp.length) {
          const { centerLongitude, centerLatitude } = temp;
          this.mapObjChart.setOption(
            this.getOption(arr, { zoom: 1.3 }, name, [centerLongitude, centerLatitude])
          );
        } else {
          this.mapObjChart.setOption(this.getOption(arr, { zoom: 1.3 }, name));
        }
      });
      window.addEventListener("resize", () => {
        this.mapObjChart.resize();
      });
    },
    getMap() {
      getLocationInfo({ locationName: "" })
        .then(async ({ data: { locationMapInfo, locationCenterInfo } }) => {
          this.locationCenterInfo = locationCenterInfo || [];
          const arr = [];
          const res = await getMapDistributionData({ level: 0, name: "中国" });

          res.data.map((item) => {
            const { name, longitude, latitude, vehicleNumbers } = item;

            arr.push({
              name: name,
              value: [Number(longitude), Number(latitude), vehicleNumbers],
            });
          });

          echarts.registerMap("china", JSON.parse(locationMapInfo));
          if (this.mapObjChart) {
            this.mapObjChart.setOption(this.getOption(arr, { zoom: 1.6 }, "china"));
          } else {
            this.initChart(arr, { zoom: 1.6 }, "china");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    this.getMap();
  },
};
</script>
<style>
.zr_111 {
  display: none !important;
}
</style>
